.experience-container {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	/* display: grid;
	grid-template-columns: 1fr;
	gap: 3rem; */
}
/* .experience-container:first-child {
	margin-left: 0 !important;
}

.experience-container:last-child {
	margin-right: 0 !important;
} */

/* ------------- Media Queries for Medium devices ------------- */
@media screen and (max-width: 1024px) {
	.experience-container {
		grid-template-columns: 1fr 1fr;
	}
}

/* ------------- Media Queries for Small devices ------------- */
@media screen and (max-width: 600px) {
	.experience-container {
		grid-template-columns: 1fr;
		gap: 1.5rem;
	}
}
