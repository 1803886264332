.section-container {
	padding: 2.4rem 5rem;
	border-radius: 2rem;
	border: 1px solid transparent;
	background: var(--color-bg-variant);
	transition: var(--transition);
}

.section-container:hover {
	background: transparent;
	border-color: var(--color-primary-variant);
	cursor: default;
}

.section-container > h3 {
	text-align: center;
	margin-bottom: 2rem;
	color: var(--color-primary);
}

.section-container > div {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 2rem;
}

.skill-article {
	display: flex;
	gap: 1rem;
	align-items: flex-start;
}

.skill-icon {
	margin-top: 6px;
}

/* ------------- Media Queries for Medium devices ------------- */
@media screen and (max-width: 1024px) {
	.section-container {
		width: 80%;
		padding: 2rem;
		margin: 0 auto;
	}

	.skill-article {
		padding: 1rem;
	}
}

/* ------------- Media Queries for Small devices ------------- */
@media screen and (max-width: 600px) {
	.section-container {
		width: 100%;
		padding: 2rem 1rem;
	}
}
