header {
	height: 100vh;
	padding-top: 7rem;
	overflow: hidden;
}

.header-container {
	height: 100%;
	text-align: center;
	position: relative;
}

.cta {
	margin-top: 2.5rem;
	display: flex;
	gap: 1.2rem;
	justify-content: center;
}

.me {
	background: linear-gradient(var(--color-primary), transparent);
	width: 22rem;
	height: 30rem;
	position: absolute;
	left: calc(
		50% - 11rem
	); /* 11rem is half of width. This can also be written as left:50%, transform: translateX(-50%);*/
	margin-top: 3.6rem;
	border-radius: 12rem 12rem 0 0;
	overflow: hidden;
	padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.scroll-down {
	position: absolute;
	right: -2.3rem;
	bottom: 5rem;
	transform: rotate(90deg);
	font-weight: 300;
	font-size: 0.9rem;
}

/* ------------- Media Queries for Medium devices ------------- */
@media screen and (max-width: 1024px) {
	header {
		height: 68vh;
	}
}

/* ------------- Media Queries for Small devices ------------- */
@media screen and (max-width: 600px) {
	header {
		height: 100vh;
	}

	.scroll-down {
		display: none;
	}
}
