.first {
	margin-left: 0 !important;
}

.last {
	margin-right: 0 !important;
}

.job-role {
	flex: 0 0 auto;
	background: var(--color-bg-variant);
	border-radius: 0 0 2rem 2rem;
	border: 1px solid var(--color-primary);
	height: fit-content;
	transition: var(--transition);
	margin: 0 2rem;
	max-width: 25rem;
}

.job-role:hover {
	background: transparent;
	border-color: var(--color-primary-variant);
	cursor: default;
}

.company-name {
	background: var(--color-primary);
	padding: 2rem;
	border-radius: 0 0 2rem 2rem;
	box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.company-name h3 {
	color: var(--color-bg);
	font-size: 1rem;
	text-align: center;
}

.responsibilities-list {
	padding: 2rem 1rem;
}

.responsibilities-list li {
	display: grid;
	grid-template-columns: 5% 90%;
	gap: 0.75rem;
	margin-bottom: 0.8rem;
}

.list-icon {
	color: var(--color-primary);
	margin-top: 2px;
}

.responsibilities-list p {
	font-size: 0.9rem;
}

/* ------------- Media Queries for Medium devices ------------- */
@media screen and (max-width: 1024px) {
	.job-role {
		height: auto;
	}
}
