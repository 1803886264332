.portfolio-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.5rem;
}

/* ------------- Media Queries for Small devices ------------- */
@media screen and (max-width: 600px) {
	.portfolio-container {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}
