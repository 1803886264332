.header-socials {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.7rem;
	position: absolute;
	left: 0;
	bottom: 3rem;
}

.header-socials::after {
	content: '';
	width: 1px;
	height: 3rem;
	background: var(--color-primary);
}

/* ------------- Media Queries for Small devices ------------- */
@media screen and (max-width: 600px) {
	.header-socials {
		display: none;
	}
}
