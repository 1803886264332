.recommendations-container {
	width: 40%;
	padding-bottom: 4rem;
}

.rec {
	background: var(--color-bg-variant);
	text-align: center;
	padding: 2rem 1rem;
	border-radius: 2rem;
	user-select: none;
	height: auto;
}
.rec-designation {
	color: var(--color-primary);
}

.rec-message {
	color: var(--color-light);
	font-weight: 300;
	display: block;
	width: 80%;
	margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	background: var(--color-primary);
}

/* ------------- Media Queries for Medium devices ------------- */
@media screen and (max-width: 1024px) {
	.recommendations-container {
		width: 60%;
	}
}

/* ------------- Media Queries for Small devices ------------- */
@media screen and (max-width: 600px) {
	.recommendations-container {
		width: var(--container-width-sm);
	}

	.rec-message {
		width: var(--container-width-sm);
	}
}
